export const NetlifyLogo = () => {
  return (
    <svg
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
    >
      <path
        d="M110.25 61.727h17.478l.273.278v2.77l-.273.277h-18.57l-.273-.277 1.091-2.77zm-109.977 0h18.57l.273.278v2.77l-.273.277H.273L0 64.775v-2.77Zm17.214-14.864H17.1l-1.931 1.959v.391l4.508 4.573 2.045-.001.274-.277v-2.073zm.002 33.054h-.387l-1.93-1.96v-.391l4.508-4.572h2.044l.275.277v2.074zm11.87-29.344V37.277l.274-.277h2.73l.274.277v13.296l-.274.277h-2.73zm0 38.929V76.206l.274-.278h2.73l.274.278v13.296l-.274.277h-2.73z"
        fill="currentColor"
      />
      <path
        d="m33.863 70.393-.273-.278.002-8.03c0-1.386-.537-2.46-2.186-2.495a42.287 42.287 0 0 0-2.854.042l-.155.162.002 10.321-.273.278h-3.278l-.274-.278V56.663l.274-.277 7.377-.068c3.696 0 5.192 2.576 5.192 5.483v8.314l-.274.278zm67.242.277c-1.094 2.772-2.186 4.434-6.012 4.434h-1.367l-.273-.277v-2.772l.273-.277h1.367c1.366 0 1.639-.277 1.913-1.109v-.277l-4.372-10.81V56.81l.273-.277h2.46l.273.277 3.279 9.425h.273l3.28-9.425.273-.277h2.459l.273.277v2.772l-4.371 11.087Zm-10.11-19.402v2.772l-.274.277h-2.458c-.547 0-.82.277-.82.832v1.109l.273.277h2.732l.274.277v2.772l-.274.277h-2.732l-.274.277v9.978l-.273.278h-3.28l-.272-.278V60.14l-.274-.278h-1.912l-.274-.277v-2.772l.274-.277h1.912l.274-.277v-1.11c0-3.048 2.185-4.158 4.644-4.158h2.46l.273.278zm-12.843 3.049h-3.279l-.273-.277v-2.772l.273-.277h3.28l.273.277v2.772zm0 16.076h-3.279l-.273-.278V56.811l.273-.277h3.28l.273.277v13.304zm-7.377 0h-3.279l-.273-.278V51.268l.273-.277h3.28l.273.277v18.847zm-10.11-4.158c0 .554.273.831.82.831h2.46l.273.278v2.771l-.274.278h-2.459c-2.46 0-4.645-1.11-4.645-4.158v-6.098l-.273-.278h-1.913l-.273-.277V56.81l.273-.277h1.913l.273-.277V53.76l.273-.277h3.28l.273.277v2.495l.273.277h3.006l.274.277v2.772l-.274.277H60.94l-.273.278v6.098zm-7.65-1.663-.274.277H44.27l-.273.277c0 .555.547 2.218 2.732 2.218.82 0 1.64-.278 1.913-.832l.273-.277h3.28l.273.277c-.274 1.663-1.64 4.158-5.739 4.158-4.645 0-6.831-3.326-6.831-7.206 0-3.88 2.185-7.206 6.558-7.206 4.373 0 6.558 3.326 6.558 7.206v1.109zm-4.1-2.772c0-.277-.273-2.217-2.46-2.217-2.185 0-2.458 1.94-2.458 2.217l.273.277h4.372z"
        fill="currentColor"
      />
    </svg>
  );
};
